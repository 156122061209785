import axios from 'axios';
import Qs from 'qs';

/**
 * API call to accept user's EULA
 *
 * @method acceptEulaAPI
 * @param  {string|number}         userId The new user information
 * @return {Promise}
 */
export const acceptEulaAPI = userId =>
    axios({
        method: 'patch',
        url: `/v2/users/${userId}/terms-accepted`,
    });

/**
 * API call to create user.
 *
 * @method createUserAPI
 * @param  {Object}         userInfo The new user information
 * @return {Promise}
 */
export const createUserAPI = userInfo => {
    const { suppressEmail, setDefaultSubscriptions } = userInfo;
    return axios({
        method: 'post',
        url: '/v2/users',
        data: userInfo,
        params: {
            partnerId: userInfo.partnerId,
            suppressEmail,
            setDefaultSubscriptions,
        },
    });
};

/**
 * API call to fetch info about the given user, via `userId`.
 *
 * @method fetchUserByIdAPI
 * @param  {Object}         userId The user to fetch
 * @return {Promise}
 */
export const fetchUserByIdAPI = userId => {
    return axios({
        method: 'get',
        url: `/v2/users/${userId}`,
    });
};

/**
 * API call to fetch preferences for the given user, via `userId`.
 *
 * @method fetchUserPreferencesByIdAPI
 * @param  {Object}         userId The user to fetch
 * @return {Promise}
 */
export const fetchUserPreferencesByIdAPI = userId => {
    return axios({
        method: 'get',
        url: `/v2/users/${userId}/preferences`,
    });
};

/**
 * API call to update preferences for the given user, via `userId`.
 *
 * @method updateUserPreferencesByIdAPI
 * @param  {Object}         userInfo The current user's information to be updated
 * @return {Promise}
 */
export const updateUserPreferencesByIdAPI = ({ userId, preferences }) => {
    return axios({
        method: 'post',
        data: preferences,
        url: `/v2/users/${userId}/preferences`,
    });
};

/**
 * API call to update user info about the given user via `userId`.
 *
 * @method updateUserByIdAPI
 * @param  {Object}         userInfo The current user's information to be updated
 * @return {Promise}
 */
export const updateUserByIdAPI = userInfo => {
    return axios({
        method: 'put',
        url: `/v2/users/${userInfo.userId}`,
        data: userInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to update user info about the given user via `userId`.
 *
 * @method changeUserStatusAPI
 * @param  {Object}         userInfo The current user's information to be updated
 * @return {Promise}
 */
export const changeUserStatusAPI = userInfo => {
    const { userId, isActive } = userInfo;
    const data = { isActive: isActive ? '1' : '0' };
    return axios({
        method: 'patch',
        url: `/v2/users/${userId}`,
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to import users.
 *
 * @method importUsersAPI
 * @param  {Object}   usersInfo
 * @return {Promise}
 */
export const importUsersAPI = usersInfo => {
    const { partnerId, file, importByPartner } = usersInfo;
    return axios({
        method: 'post',
        url: `/v2/users/bulk-async?partnerId=${partnerId}&importByPartner=${importByPartner}&suppressEmail=true`,
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * API call list users by current user logged in.
 *
 * @method fetchUsersAPI
 * @param  {Object}         data
 * @return {Promise}
 */
export const fetchUsersAPI = data => {
    return axios({
        method: 'get',
        url: '/v2/users',
        params: data,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * API to update user terms and conditions status
 *
 * @method  updateUserTermsAndConditionsStatus
 * @param   {Object}        userInfo
 * @return  {Promise}
 */
export const updateUserTermsAndConditionsStatus = ({ userId }) =>
    axios({
        method: 'put',
        url: `/v2/users/terms_accepted/${userId}`,
    });

/**
 * API call to download csv template to import users.
 *
 * @method downloadCsvUserTemplateAPI
 * @param  {Object}         partnerId
 * @return {Promise}
 */
export const downloadCsvUserTemplateAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/users/csv?type=template&partnerId=${partnerId}`,
    });
};

/**
 * API to fetch a preview of a user's email history
 *
 * @method fetchUserEmailHistoryPreviewAPI
 * @param  {string} userEmail
 * @param  {string/Number} userId
 * @return {Promise}
 */
export const fetchUserEmailHistoryPreviewAPI = ({ email, userId }) =>
    axios({
        method: 'get',
        url: `/v2/email-history?recipient=${email}&sort=createdOn&limit=15&userId=${userId}`,
    });

/**
 * API to export users CSV.
 *
 * @method exportUsersCSV
 * @param  {Object}     params
 * @return {Promise}
 */
export const exportUsersCSV = params =>
    axios({
        method: 'get',
        url: '/v2/users',
        params,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: {
            Accept: 'text/csv',
        },
        responseType: 'blob',
    });

/**
 * API to fetch dashboard widgets for a user
 *
 * @method fetchDashboardWidgetsAPI
 * @param  {number|string}     userId
 * @return {Promise}
 */
export const fetchDashboardWidgetsAPI = userId =>
    axios({
        method: 'get',
        url: `/v2/users/${userId}/widgets`,
    });

/**
 * API to update dashboard widgets for a user
 *
 * @method updateDashboardWidgetsAPI
 * @param  {number|string}     userId
 * @param  {Array}             widgets
 * @return {Promise}
 */
export const updateDashboardWidgetsAPI = (userId, widgets) =>
    axios({
        data: {
            widgets,
        },
        method: 'put',
        url: `/v2/users/${userId}/widgets`,
    });

/**
 * API to check subscription status for a user
 *
 * @method checkUserSubscriptionStatusAPI
 * @param  {string}     token
 * @param  {string}     email
 * @param  {boolean}     isProspect
 * @return {Promise}
 */
export const checkUserSubscriptionStatusAPI = ({ token, isProspect, email }) =>
    axios({
        ignoreAuthorization: true,
        method: 'get',
        params: {
            token,
            isProspect,
            email,
        },
        preventRefreshToken: true,
        url: '/v2/user-preferences/unsubscribe',
    });

/**
 * API to unsubscribe user from subscriptions
 *
 * @method unsubscribeUserFromSubscriptionsAPI
 * @param  {string}     token
 * @param  {string}     isProspect
 * @param  {string}     email
 * @return {Promise}
 */
export const unsubscribeUserFromSubscriptionsAPI = ({ isProspect, token, email }) =>
    axios({
        ignoreAuthorization: true,
        method: 'patch',
        params: {
            token,
            isProspect,
            email,
        },
        preventRefreshToken: true,
        url: '/v2/user-preferences/unsubscribe',
    });

/**
 * API to bulk user subscriptions
 *
 * @method bulkUserSubscriptionsAPI
 * @param  {Object}     payload
 * @return {Promise}
 */
export const bulkUserSubscriptionsAPI = payload => {
    const { partnerId, ...data } = payload;
    return axios({
        method: 'post',
        url: '/v2/user-preferences/bulk',
        params: { partnerId },
        data,
    });
};

/**
 * API call to update partner configurations.
 *
 * @method updatePartnerConfigurationsAPI
 * @param  {string|number}         userId The new user information
 * @return {Promise}
 */
export const updatePartnerConfigurationsAPI = ({ partnerId }) =>
    axios({
        method: 'post',
        url: `/v2/partner-configurations/${partnerId}/accept-banner-ads-tos?bannerAdsTOSAccepted=true`,
    });

/**
 * API call to update additional info about the given user, via `userInfo`.
 *
 * @method updateUsersAdditionalDataAPI
 * @param  {Object}         userInfo The user to fetch
 * @return {Promise}
 */
export const updateUsersAdditionalDataAPI = userInfo => {
    const { userId, id, ...rest } = userInfo;
    return axios({
        method: 'put',
        url: `/v2/users/${userId}/additional-data/${id}`,
        data: rest,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to create additional info about the given user, via `userInfo`.
 *
 * @method createUsersAdditionalDataAPI
 * @param  {Object}         userInfo The user to fetch
 * @return {Promise}
 */
export const createUsersAdditionalDataAPI = userInfo => {
    const { userId, ...rest } = userInfo;
    return axios({
        method: 'post',
        url: `/v2/users/${userId}/additional-data`,
        data: rest,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to fetch additional info about the given user, via `userInfo`.
 *
 * @method fetchUsersAdditionalDataAPI
 * @param  {Object}         userInfo The user to fetch
 * @return {Promise}
 */
export const fetchUsersAdditionalDataAPI = userInfo => {
    const { userId, id } = userInfo;
    return axios({
        method: 'get',
        url: `/v2/users/${userId}/additional-data/${id}`,
    });
};

/**
 * API call to get additional info about the given user, via `userInfo`.
 *
 * @method getUsersAdditionalDataAPI
 * @param  {Object}         userInfo The user to fetch
 * @return {Promise}
 */
export const getUsersAdditionalDataAPI = userInfo => {
    const { userId } = userInfo;
    return axios({
        method: 'get',
        url: `/v2/users/${userId}/additional-data`,
    });
};
