import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the user flow.
 *
 * @type {Object}
 */
export const fetchUser = createActions('user', 'FETCH_USER');

/*
 * Redux actions for fetching a user.
 *
 * @type {Object}
 */
export const fetchUserPreferences = createActions('user', 'FETCH_USER_PREFERENCES', {
    asPromise: true,
});

/*
 * Redux actions for fetching a user.
 *
 * @type {Object}
 */
export const update = createActions('user', 'FETCH_USER_PREFERENCES');

/**
 * Redux actions for fetching the user's company flow. This action returns a PARTNER.
 *
 * @type {Object}
 */
export const fetchCompany = createActions('user', 'FETCH_COMPANY', {
    asPromise: true,
});

/**
 * Redux actions for fetching the user's company flow. This action returns a PARTNER.
 *
 * @type {Object}
 */
export const fetchCompanyLocations = createActions('user', 'FETCH_COMPANY_LOCATIONS');

/**
 * Redux actions for fetching the user's partner flow.
 *
 * @type {Object}
 */
export const fetchPartner = createActions('user', 'FETCH_PARTNER');

/**
 * Redux actions for fetching the user's configuration flow.
 *
 * @type {Object}
 */
export const fetchConfiguration = createActions('user', 'FETCH_CONFIGURATION');

/**
 * Redux actions for fetching the user's permissions flow.
 *
 * @type {Object}
 */
export const fetchPermissions = createActions('user', 'FETCH_PERMISSIONS');

/**
 * Redux actions for building the user's permissions.
 *
 * @type {Object}
 */
export const buildPermissions = createActions('user', 'BUILD_PERMISSIONS');

/**
 * Redux actions for triggering user interest in a handbook feature
 *
 * @type {Object}
 */
export const triggerUserInterestInHandbookFeature = createActions(
    'user',
    'TRIGGER_USER_INTEREST_IN_HANDBOOK_FEATURE',
    { asPromise: true }
);

/**
 * Redux actions for updating the user.
 *
 * @type {Object}
 */
export const updateUser = createActions('user', 'UPDATE_USER', { asPromise: true });

/**
 * Redux actions for updating the user.
 *
 * @type {Object}
 */
export const updateUserPreferences = createActions('user', 'UPDATE_USER_PREFERENCES', {
    asPromise: true,
});

/**
 * Redux actions for updating the user's terms and conditions status.
 *
 * @type {Object}
 */
export const updateUserTermsAndConditionsStatus = createActions(
    'user',
    'UPDATE_TERMS_AND_CONDITIONS',
    { asPromise: true }
);

/**
 * Redux actions to check user subscription status.
 *
 * @type {Object}
 */
export const checkUserSubscriptionStatus = createActions('user', 'CHECK_SUBSCRIPTION_STATUS', {
    asPromise: true,
});

/**
 * Redux actions to unsubscribe user from subscriptions
 *
 * @type {Object}
 */
export const unsubscribeUserFromSubscriptions = createActions('user', 'UNSUBSCRIBE_USER', {
    asPromise: true,
});

/**
 * Redux actions to bulk user subscriptions
 *
 * @type {Object}
 */
export const bulkUserSubscriptions = createActions('user', 'BULK_USER_SUBSCRIPTIONS', {
    asPromise: true,
});

/**
 * Redux actions for fetch content type.
 *
 * @type {Object}
 */
export const fetchAllContentTypes = createActions('user', 'FETCH_ALL_CONTENT_TYPES', {
    asPromise: true,
});

/**
 * Redux actions for fetch information banner
 *
 * @type {Object}
 */
export const fetchInformationBanner = createActions('user', 'FETCH_INFORMATION_BANNER');

/**
 * Redux actions for fetch content type.
 *
 * @type {Object}
 */
export const fetchPartnerSettings = createActions('user', 'FETCH_PARTNER_SETTINGS');

/**
 * Fetch role
 *
 * @type {Object}
 */
export const fetchUserRole = createActions('user', 'FETCH_USER_ROLE', {
    asPromise: true,
});

/**
 * Update EULA status
 *
 * @type {Object}
 */
export const updateEULAStatus = createActions('user', 'UPDATE_EULA_STATUS');

/**
 * Update Company Details Reminder status
 *
 * @type {Object}
 */
export const updateCompanyDetailsReminderStatus = createActions(
    'user',
    'UPDATE_COMPANY_DETAILS_REMINDER_STATUS'
);

/**
 * Accept EULA for a user
 *
 * @type {Object}
 */
export const acceptEULA = createActions('user', 'ACCEPT_EULA', { asPromise: true });

/**
 * Action to update Partner Settings
 *
 * @type {Object}
 */
export const updatePartnerSettings = createActions('user', 'UPDATE_PARTNER_SETTINGS', {
    asPromise: true,
});

/**
 * Redux actions to reset partner-settings.
 *
 * @type {Object}
 */
export const resetPartnerSettings = createActions('user', 'RESET_PARTNER_SETTINGS', {
    asPromise: true,
});

/**
 * Redux actions to update welcome-insights-status.
 *
 * @type {Object}
 */
export const updateWelcomeInsightsStatus = createActions('user', 'WELCOME_INSIGHTS_STATUS');

/**
 * Redux actions to update safety manuel template url.
 *
 * @type {Object}
 */
export const updateSafetyManualTemplateUrl = createActions('user', 'SAFETY_MANUAL_TEMPLATE_URL');

/**
 * Redux actions to update handbook archived status.
 *
 * @type {Object}
 */
export const updateArchivedHandbookStatus = createActions('user', 'ARCHIVED_HANDBOOK_STATUS');

/**
 * Redux actions to click on safety menu navigation.
 *
 * @type {Object}
 */
export const updateClickSafetyMenu = createActions('user', 'CLICK_SAFETY_MENU');

/**
 * Redux actions to update sponsorship list.
 *
 * @type {Object}
 */
export const updateSponsorshipList = createActions('user', 'UPDATE_SPONSORSHIP_LIST');

/**
 * Redux actions to update partner Ads Tos Acceptance.
 *
 * @type {Object}
 */
export const updatePartnerAdsTosAcceptance = createActions(
    'user',
    'UPDATE_PARTNER_ADS_TOS_ACCEPTANCE'
);

/**
 * Redux action to update Partner Configurations
 *
 * @type {Object}
 */
export const updatePartnerConfigurations = createActions('user', 'UPDATE_PARTNER_CONFIGURATIONS');

/**
 * Redux action to update import status
 *
 * @type {Object}
 */
export const updateImportStatus = createActions('user', 'UPDATE_IMPORT_STATUS', {
    payloadCreator: payload => payload,
});

/**
 * Redux actions for getting the user additional data.
 *
 * @type {Object}
 */
export const fetchUsersAdditionalData = createActions('user', 'FETCH_USERS_ADDITIONAL_DATA', {
    asPromise: true,
});
/**
 * Redux actions for updating additional data for the user.
 *
 * @type {Object}
 */
export const updateUsersAdditionalData = createActions('user', 'UPDATE_USERS_ADDITIONAL_DATA', {
    asPromise: true,
});
/**
 * Redux actions for creating additional data for the user.
 *
 * @type {Object}
 */
export const createUsersAdditionalData = createActions('user', 'CREATE_USERS_ADDITIONAL_DATA', {
    asPromise: true,
});
/**
 * Redux actions for get additional data for the user.
 *
 * @type {Object}
 */
export const getUsersAdditionalData = createActions('user', 'GET_USERS_ADDITIONAL_DATA', {
    asPromise: true,
});
