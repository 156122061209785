import get from 'lodash/get';

import Acl from 'modules/acl';
import { isGenesisInternalUser } from 'utils/user';
import { addSchemaValue } from 'ravenjs/utils/schema';
import _cloneDeep from 'lodash/cloneDeep';

import { configContainsSku } from './configurations';

import { CLIENT_SOLUTION_REPORT, MAP_SKU_KEY } from '../constants/reports';

/**
 * Get subnavigation links.
 *
 * @method getFormattedLinks
 * @param  {Object} items
 * @return {Object}
 */
export const getFormattedLinks = items => {
    const links = items.map(({ title, to, permission }) => {
        if (Acl.check(permission)) {
            return {
                label: title,
                jumpTo: to,
            };
        }
        return null;
    });
    return links.filter(el => el != null);
};

export const isClientSolutionReportDisabled = (userCompany, userRole) => {
    const hasLicense = Boolean(userCompany?.licenses?.length > 0);
    const isLoggedInIntuitPartner =
        userCompany?.displayName === CLIENT_SOLUTION_REPORT.INTUIT_PARTNER_NAME;
    const isLoggedInUserRoleInternal = isGenesisInternalUser(userRole);

    if (isLoggedInUserRoleInternal) {
        return false;
    }

    return !hasLicense || isLoggedInIntuitPartner;
};

export const isPartnerValueReportDisabled = (userCompany, userRole) => {
    const companySkus = get(userCompany, 'skus', []);
    const hasMapSku = configContainsSku({ skus: companySkus }, MAP_SKU_KEY.SKU_KEY);

    if (isGenesisInternalUser(userRole) || hasMapSku) {
        return false;
    }

    return true;
};

export const getEsignatureReportingSchema = options => {
    const { reportSchema, maxTotalSelected, resetAll, forceSelectAll } = options;

    const newReportSchema = _cloneDeep(reportSchema);

    addSchemaValue(
        newReportSchema,
        'uiReportingSchema.esignatureReporting.selectHandbook.ui:options.maxTotalSelected',
        maxTotalSelected
    );

    if (forceSelectAll) {
        addSchemaValue(
            newReportSchema,
            'uiReportingSchema.esignatureReporting.selectHandbook.ui:options.forceSelectAll',
            forceSelectAll
        );
    } else {
        addSchemaValue(
            newReportSchema,
            'uiReportingSchema.esignatureReporting.selectHandbook.ui:options.resetAll',
            resetAll
        );
    }

    addSchemaValue(
        newReportSchema,
        'uiReportingSchema.esignatureReporting.selectHandbook.ui:options.defaultOptions',
        []
    );

    return newReportSchema;
};

export const handleFormChangeUtility = async ({ formData, state, fetchTotalDocument }) => {
    const { isMineralRole, reportSchema, previousCompany, isClientRole } = state;
    const currentPartner = get(formData, 'esignatureReporting.selectPartner.value', '');
    const currentCompany = get(formData, 'esignatureReporting.selectCompany.value', '');

    if (isClientRole) {
        return { ...state };
    }

    if (!currentPartner && isMineralRole) {
        const updateFormSchema = _cloneDeep(reportSchema);
        formData.esignatureReporting.selectCompany = null;
        formData.esignatureReporting.selectHandbook = null;

        addSchemaValue(
            updateFormSchema,
            'uiReportingSchema.esignatureReporting.selectCompany.ui:options.inputValue',
            null
        );
        addSchemaValue(
            updateFormSchema,
            'uiReportingSchema.esignatureReporting.selectCompany.ui:options.value',
            null
        );

        return {
            formData: {},
            previousCompany: '',
            messageSuccess: '',
            showSuccess: false,
            reportSchema: updateFormSchema,
        };
    }

    if (!currentCompany) {
        const updateFormSchema = getEsignatureReportingSchema({
            reportSchema,
            maxTotalSelected: 0,
            resetAll: true,
        });

        return {
            formData: {},
            previousCompany: '',
            messageSuccess: '',
            showSuccess: false,
            excludeHandbooks: [],
            includeHandbook: [],
            reportSchema: updateFormSchema,
            totalDocument: 0,
        };
    } else {
        if (currentCompany !== previousCompany) {
            let updateFormSchema = _cloneDeep(reportSchema);
            addSchemaValue(
                updateFormSchema,
                'uiReportingSchema.esignatureReporting.selectHandbook.ui:options.resetAll',
                true
            );

            const totalDocuments = await fetchTotalDocument(currentCompany, true);

            updateFormSchema = getEsignatureReportingSchema({
                reportSchema: updateFormSchema,
                maxTotalSelected: totalDocuments,
            });

            return {
                previousCompany: currentCompany,
                reportSchema: updateFormSchema,
            };
        }

        return { previousCompany: currentCompany };
    }
};
