import { takeLatest } from 'redux-saga/effects';

import * as userActions from '../actions';
import { buildUserPermissionsSaga, fetchPermissionsSaga } from './permissions';
import { fetchPartnerSaga } from './broker';
import { fetchCompanyLocationsSaga, fetchCompanySaga } from './company';
import { fetchConfigurationSaga } from './configuration';
import { fetchAllContentTypesSaga } from './content';

import {
    acceptEULASaga,
    bulkUserSubscriptionsSaga,
    checkUserSubscriptionStatusSaga,
    createUsersAdditionalDataSaga,
    fetchUserPreferencesSaga,
    fetchUserRoleSaga,
    fetchUsersAdditionalDataSaga,
    fetchUserSaga,
    getUsersAdditionalDataSaga,
    resetPartnerSettingsSaga,
    triggerUserInterestInHandbookFeatureSaga,
    unsubscribeUserFromSubscriptionsSaga,
    updateClickSafetyMenuSaga,
    updateCompanyDetailsReminderStatusSaga,
    updateEULAStatusSaga,
    updateImportStatusSaga,
    updatePartnerConfigurationsSaga,
    updatePartnerSettingsSaga,
    updateUserPreferencesSaga,
    updateUsersAdditionalDataSaga,
    updateUserSaga,
    updateUserTermsAndConditionsStatusSaga,
    updateWelcomeInsightsStatusSaga,
} from './user';

/**
 * The root `user` saga.
 * We're triggering sagas based their respective events.
 *
 * @method userSaga
 * @type   {Generator}
 */
function* userSaga() {
    // Fetch a single user
    yield takeLatest(userActions.fetchUser.TRIGGER, fetchUserSaga);
    // Fetch a user's preferences
    yield takeLatest(userActions.fetchUserPreferences.TRIGGER, fetchUserPreferencesSaga);
    // Fetch a user's company
    yield takeLatest(userActions.fetchCompany.TRIGGER, fetchCompanySaga);
    // Fetch a user's partner
    yield takeLatest(userActions.fetchPartner.TRIGGER, fetchPartnerSaga);
    // Fetch a user's configuration
    yield takeLatest(userActions.fetchConfiguration.TRIGGER, fetchConfigurationSaga);
    // Fetch the currently logged in user's permissions
    yield takeLatest(userActions.fetchPermissions.TRIGGER, fetchPermissionsSaga);
    // Trigger the user interest in a handbook feature
    yield takeLatest(
        userActions.triggerUserInterestInHandbookFeature.TRIGGER,
        triggerUserInterestInHandbookFeatureSaga
    );
    yield takeLatest(
        userActions.updateUserTermsAndConditionsStatus.TRIGGER,
        updateUserTermsAndConditionsStatusSaga
    );
    // Update the current logged in user
    yield takeLatest(userActions.updateUser.TRIGGER, updateUserSaga);
    yield takeLatest(userActions.updateUserPreferences.TRIGGER, updateUserPreferencesSaga);
    yield takeLatest(
        userActions.checkUserSubscriptionStatus.TRIGGER,
        checkUserSubscriptionStatusSaga
    );
    yield takeLatest(
        userActions.unsubscribeUserFromSubscriptions.TRIGGER,
        unsubscribeUserFromSubscriptionsSaga
    );
    yield takeLatest(userActions.bulkUserSubscriptions.TRIGGER, bulkUserSubscriptionsSaga);
    yield takeLatest(userActions.fetchAllContentTypes.TRIGGER, fetchAllContentTypesSaga);
    yield takeLatest(userActions.fetchUserRole.TRIGGER, fetchUserRoleSaga);
    yield takeLatest(userActions.updateEULAStatus.TRIGGER, updateEULAStatusSaga);
    yield takeLatest(
        userActions.updateCompanyDetailsReminderStatus.TRIGGER,
        updateCompanyDetailsReminderStatusSaga
    );
    yield takeLatest(userActions.acceptEULA.TRIGGER, acceptEULASaga);
    yield takeLatest(userActions.updatePartnerSettings.TRIGGER, updatePartnerSettingsSaga);
    yield takeLatest(userActions.resetPartnerSettings.TRIGGER, resetPartnerSettingsSaga);
    yield takeLatest(userActions.fetchCompanyLocations.TRIGGER, fetchCompanyLocationsSaga);
    yield takeLatest(
        userActions.updateWelcomeInsightsStatus.TRIGGER,
        updateWelcomeInsightsStatusSaga
    );
    yield takeLatest(userActions.updateClickSafetyMenu.TRIGGER, updateClickSafetyMenuSaga);
    yield takeLatest(
        userActions.updatePartnerConfigurations.TRIGGER,
        updatePartnerConfigurationsSaga
    );
    yield takeLatest(userActions.updateImportStatus.TRIGGER, updateImportStatusSaga);
    yield takeLatest(userActions.updateUsersAdditionalData.TRIGGER, updateUsersAdditionalDataSaga);
    yield takeLatest(userActions.fetchUsersAdditionalData.TRIGGER, fetchUsersAdditionalDataSaga);
    yield takeLatest(userActions.createUsersAdditionalData.TRIGGER, createUsersAdditionalDataSaga);
    yield takeLatest(userActions.getUsersAdditionalData.TRIGGER, getUsersAdditionalDataSaga);
    yield takeLatest(userActions.buildPermissions.TRIGGER, buildUserPermissionsSaga);
}

// Default export is the module's root saga.
export default userSaga;

// Named exports are all the internal sagas that can be used
// for testing as well as imports in other modules
export {
    acceptEULASaga,
    bulkUserSubscriptionsSaga,
    checkUserSubscriptionStatusSaga,
    createUsersAdditionalDataSaga,
    fetchUserPreferencesSaga,
    fetchUserRoleSaga,
    fetchUsersAdditionalDataSaga,
    fetchUserSaga,
    getUsersAdditionalDataSaga,
    resetPartnerSettingsSaga,
    triggerUserInterestInHandbookFeatureSaga,
    unsubscribeUserFromSubscriptionsSaga,
    updateClickSafetyMenuSaga,
    updateCompanyDetailsReminderStatusSaga,
    updateEULAStatusSaga,
    updateImportStatusSaga,
    updatePartnerConfigurationsSaga,
    updatePartnerSettingsSaga,
    updateUserPreferencesSaga,
    updateUsersAdditionalDataSaga,
    updateUserSaga,
    updateUserTermsAndConditionsStatusSaga,
    updateWelcomeInsightsStatusSaga,
};
