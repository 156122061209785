import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput, { conformToMask } from 'react-text-mask';

import Input from './Input';

/**
 * An Input Mask component, encapsulating the default Input Component.
 * See the @link for more documentation on how to use the Input Mask.
 *
 * @see {@link https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md}
 * @method      InputMask
 * @param       {Object}  props The props
 * @constructor
 */
function InputMask(props) {
    const {
        className,

        disabled,
        mask,
        onChange,
        placeholder,
        value,
        required,
        onClick,
        onBlur,
        readOnly,
        showRequired,
        onKeyDown,
        guide,
    } = props;
    // Transform the passed in value via the given mask.
    const conformedValue =
        Array.isArray(mask) && mask.length > 0 && value
            ? conformToMask(`${value}`, mask).conformedValue
            : value;

    function render(ref, inputProps) {
        return <Input ref={ref} {...inputProps} />;
    }

    return (
        <MaskedInput
            className={className}
            disabled={disabled}
            required={required}
            value={conformedValue}
            mask={mask}
            onChange={onChange}
            placeholder={placeholder}
            render={render}
            onClick={onClick}
            onBlur={onBlur}
            readOnly={readOnly}
            showRequired={showRequired}
            onKeyDown={onKeyDown}
            guide={guide}
        />
    );
}

InputMask.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    /**
     * Array or a function that defines how the user input is going to be masked
     */
    mask: PropTypes.array,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    showRequired: PropTypes.bool,
    guide: PropTypes.bool,
    onKeyDown: PropTypes.func,
};

InputMask.defaultProps = {
    className: '',
    disabled: false,
    mask: null,
    placeholder: '',
    required: false,
    value: null,
    onBlur: () => {},
    onChange: () => {},
    onClick: () => {},
    readOnly: false,
    showRequired: false,
    guide: true,
    onKeyDown: () => {},
};

export default InputMask;
