import { takeLatest } from 'redux-saga/effects';

import * as webhookActions from '../actions';
import {
    fetchSecretsByGroupIdSaga,
    createSecretByGroupIdSaga,
    fetchWebhookRulesSaga,
    createWebhookRuleSaga,
    deleteWebhookRulesSaga,
} from './webhook';

/**
 * Root saga for webhooks
 * Triggers sagas related to all tools events
 *
 * @method webhookSaga
 * @type   {Generator}
 */
function* webhookSaga() {
    yield takeLatest(webhookActions.fetchSecretsByGroupId.TRIGGER, fetchSecretsByGroupIdSaga);
    yield takeLatest(webhookActions.createSecretByGroupId.TRIGGER, createSecretByGroupIdSaga);
    yield takeLatest(webhookActions.fetchWebhookRules.TRIGGER, fetchWebhookRulesSaga);
    yield takeLatest(webhookActions.createWebhookRule.TRIGGER, createWebhookRuleSaga);
    yield takeLatest(webhookActions.deleteWebhookRules.TRIGGER, deleteWebhookRulesSaga);
}

export default webhookSaga;

export {
    fetchSecretsByGroupIdSaga,
    createSecretByGroupIdSaga,
    fetchWebhookRulesSaga,
    createWebhookRuleSaga,
    deleteWebhookRulesSaga,
};
