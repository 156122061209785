import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Box from 'ravenjs/lib/Box';
import Radio from 'ravenjs/lib/Radio';
import Typography from 'ravenjs/lib/Typography';

import _isEmpty from 'lodash/isEmpty';
import DisplayRequiredWithIconField from '../fields/DisplayRequiredWithIconField';

function RadioWidget(props) {
    const { autofocus, disabled, id, onChange, options, readonly, required, schema, value } = props;
    // Generate a unique field name to identify this set of radio buttons.
    const name = `RadioWidget__${id}`;
    // Extract props from the options.
    const {
        boxProps = {},
        enumOptions,
        enumDisabled,
        inline,
        typographyProps = {},
        inactiveColor = null,
        hideRequired = true,
    } = options;

    const formContext = _get(props, 'formContext', null);
    const taintedFields = _get(props, 'formContext.taintedFields', []);
    const pristine = _get(props, 'formContext.pristine', {});

    const { showRequiredOnFormLoad } = schema;

    const modifiedValue = props.value !== null ? value.toString() : value;

    const showRequired =
        !hideRequired &&
        formContext &&
        (taintedFields.includes(props.id) || !pristine || showRequiredOnFormLoad) &&
        props.required &&
        _isEmpty(modifiedValue);

    // Render the `RadioWidget`.
    return (
        <Box borderRadius="0" borderWidth="0" margin="0" padding="0 5px" {...boxProps}>
            {enumOptions.map((option, optionIdx) => {
                const checked = option.value === value;
                const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
                const isDisabled = disabled || itemDisabled || readonly;
                const inactiveColorValue = isDisabled ? inactiveColor : null;
                const typographyExtendedProps = isDisabled
                    ? {
                          ...typographyProps,
                          color: inactiveColor,
                      }
                    : { ...typographyProps };

                const key = `${name}__${id}__${optionIdx}`;
                // Build the single radio.
                return (
                    <Typography
                        {...typographyExtendedProps}
                        fullWidth={inline ? null : true}
                        gutterRight={inline ? '20px' : null}
                        htmlFor={key}
                        key={key}
                        type="label"
                    >
                        <Radio
                            autoFocus={autofocus && optionIdx === 0}
                            checked={checked}
                            disabled={disabled || itemDisabled || readonly}
                            id={key}
                            margin="0 5px 0 0"
                            name={name}
                            onChange={() => onChange(option.value)}
                            required={required}
                            value={option.value}
                            inactiveColor={inactiveColorValue}
                        />
                        <span>{option.label}</span>
                    </Typography>
                );
            })}
            {showRequired && <DisplayRequiredWithIconField />}
        </Box>
    );
}

RadioWidget.propTypes = {
    autofocus: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.shape({
        enumOptions: PropTypes.array,
        enumDisabled: PropTypes.array,
        inline: PropTypes.bool,
        typographyProps: PropTypes.object,
        boxProps: PropTypes.object,
        inactiveColor: PropTypes.string,
        hideRequired: PropTypes.bool,
    }),
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    value: PropTypes.any,
};

RadioWidget.defaultProps = {
    autofocus: false,
    disabled: null,
    onChange: null,
    options: null,
    readonly: null,
    required: null,
    value: null,
};

export default RadioWidget;
